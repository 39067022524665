<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter :orderbutton="true" :orderType="1" :listStatus="listStatus" :headers="headers" :viewName="viewName" :orderSummary="orderSummary" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-widget title="Thống kê chi phí" v-if="selectedOrders.length > 0">
              <div slot="widget-content">
                  <v-layout class="font-weight-bold" row wrap>
                    <v-flex lg3 sm3 pa-0>
                        <v-card-text>Số đơn hàng:</v-card-text>
                    </v-flex>
                    <v-flex lg9 sm9 pa-0>
                        <v-card-text>{{selectedOrders.length}}</v-card-text>
                    </v-flex>
                  </v-layout>
                  <v-layout class="font-weight-bold" row wrap>
                    <v-flex lg3 sm3 pa-0>
                        <v-card-text>Tổng tiền:</v-card-text>
                    </v-flex>
                    <v-flex lg9 sm9 pa-0>
                        <v-card-text>{{selectedOrders.map(item => item.TotalAmountVND).reduce((prev, curr) => prev + curr, 0) | currency}}</v-card-text>
                    </v-flex>
                  </v-layout>
                  <v-layout class="font-weight-bold" row wrap>
                    <v-flex lg3 sm3 pa-0>
                        <v-card-text>Đã cọc:</v-card-text>
                    </v-flex>
                    <v-flex lg9 sm9 pa-0>
                        <v-card-text>{{selectedOrders.map(item => item.Deposit).reduce((prev, curr) => prev + curr, 0) | currency}}</v-card-text>
                    </v-flex>
                  </v-layout>
                  <v-layout class="font-weight-bold" row wrap>
                    <v-flex lg3 sm3 pa-0>
                        <v-card-text>Cần cọc:</v-card-text>
                    </v-flex>
                    <v-flex lg9 sm9 pa-0>
                        <v-card-text>{{selectedOrders.map(item => item.MinDeposit).reduce((prev, curr) => prev + curr, 0) | currency}}</v-card-text>
                    </v-flex>
                  </v-layout>
                  <v-layout class="font-weight-bold" row wrap>
                    <v-flex lg3 sm3 pa-0>
                        <v-card-text>Còn thiếu:</v-card-text>
                    </v-flex>
                    <v-flex lg9 sm9 pa-0>
                        <v-card-text>{{(selectedOrders.map(item => item.TotalAmountVND).reduce((prev, curr) => prev + curr, 0) - selectedOrders.map(item => item.Deposit).reduce((prev, curr) => prev + curr, 0)) | currency}}</v-card-text>
                    </v-flex>
                  </v-layout>
              </div>
          </v-widget>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="orders"
            :must-sort="true"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :loading="loading"
            show-select
            :mobile-breakpoint="100"
            v-model="selectedOrders"
            item-key="ID"
            class="elevation-1 table-wrap-text"
          >
            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
            <template v-slot:item="{ item, isSelected, select}">
              <tr :active="isSelected">
                <td @click="select(!isSelected)"><v-checkbox primary hide-details :input-value="isSelected"></v-checkbox></td>
                <td><a href="#" @click="gotoDetail(item.ID)">{{ 'MD'+item.ID }}</a></td>
                <td> 
                  <v-chip :color="get_order_status_color(item.Status)" label outlined small> {{ item.Status | order_status}}</v-chip>
                </td>
                <td>
                  <v-img :src="item.image_origin && item.image_origin.includes('firebase') ? item.image_origin : decodeURIComponent(item.image_origin)" width="64px" height="64px"></v-img>
                </td>                
                <td v-if="item.OrderCodeCN || item.Status == statusEnum.huy  || item.Status == statusEnum.cho_dat_coc  || item.Status == statusEnum.cho_bao_gia">{{ item.OrderCodeCN }}</td>
                <td v-else>
                  <v-edit-dialog
                    :return-value.sync="item.OrderCodeCN"
                    large
                    lazy
                    @save="saveDingdanhao(item)"
                  >
                    <div>{{ item.OrderCodeCN }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="newOrderCodeCN" label="Dingdanhao" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-if="item.OrderCodeCN && !item.TransactionCode">
                  <v-edit-dialog
                    :return-value.sync="item.TransactionCode"
                    large
                    lazy
                    @save="saveTransactionCode(item)"
                  >
                    <div>{{ item.TransactionCode }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="newTransactionCode" label="Mã bill" single-line autofocus></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-else>{{ item.TransactionCode }}</td>
                <td class="text-end font-weight-bold" v-if="item.OrderCodeCN && (item.Status == statusEnum.dang_dat_hang  || item.Status == statusEnum.da_mua_hang  || item.Status == statusEnum.dang_van_chuyen || adminRole)">
                  <v-edit-dialog
                    :return-value.sync="item.RealAmount"
                    large
                    lazy
                    @save="saveDingdanhao(item)"
                  >
                    <div>{{ item.RealAmount | currency('¥',2) }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.RealAmount" :decimal="2" label="Phí mua thật" autofocus ></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end font-weight-bold" v-else>{{ item.RealAmount | currency('',2)}}</td>
                <td class="text-end font-weight-bold">
                  <v-edit-dialog
                    :return-value.sync="item.ReviewBonus"
                    large
                    lazy
                    @save="saveOrder({ID: item.ID, ReviewBonus: item.ReviewBonus})"
                  >
                    <div>{{ item.ReviewBonus | currency('¥',2) }}</div>
                    <template v-slot:input>
                      <h-currency-input v-model="item.ReviewBonus" :decimal="2" label="Bình luận" autofocus ></h-currency-input>
                    </template>
                  </v-edit-dialog>
                </td>
                <td class="text-end font-weight-bold">
                  {{ item.TotalAmountVND | currency}}
                  <br />
                  {{ item.TotalAmountVND/item.ExchangeRate | currency('¥',2)}}
                </td>
                <!-- <td class="text-end font-weight-bold">{{ item.MinDeposit | currency}}</td> -->
                <td v-if="orderRole & 1">
                  <a target="_blank" :href="'khach-hang/quan-ly-khach-hang/' + item.UID">{{ item.CustomerName }}</a>
                </td>
                <td v-else>
                  {{ item.CustomerName }}
                </td>
                <td v-if="orderRole & 512">
                  <v-edit-dialog
                    :return-value.sync="item.BuyerName"
                    large
                    lazy
                    persistent
                    @save="saveStaff(item.ID, item.BuyerID, item.CustomerServiceID)"
                  >
                    <div> {{ item.BuyerID ? 'CSKH:' + item.BuyerName : '' }}<br/> {{ item.CustomerServiceID ? 'Tiếng Trung:' + item.CSName : '' }}</div>
                    <template v-slot:input>
                      <v-select
                        label="CSKH"
                        :items="p_staffs"
                        v-model="item.BuyerID"
                        item-text="FullName"
                        item-value="ID"
                        v-on:change="item.BuyerName = getStaffName(item.BuyerID)"
                      ></v-select>
                      <v-select
                        label="Tiếng Trung"
                        :items="cs_staffs"
                        v-model="item.CustomerServiceID"
                        item-text="FullName"
                        item-value="ID"
                        v-on:change="item.CSName = getStaffName(item.CustomerServiceID)"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-else>
                  {{ item.BuyerID ? 'Mua hàng:' + item.BuyerName : '' }}<br/> {{ item.CustomerServiceID ? 'Tiếng Trung:' + item.CSName : '' }}
                </td>
                <!-- <td v-if="(orderRole & 1024)">
                  <v-edit-dialog
                    :return-value.sync="item.CSName"
                    large
                    lazy
                    persistent
                    @save="saveStaff(item.ID, item.BuyerID, item.CSID)"
                  >
                    <div>{{ item.CSName }}</div>
                    <template v-slot:input>
                      <v-select
                        :items="cs_staffs"
                        v-model="item.CSID"
                        item-text="FullName"
                        item-value="ID"
                        v-on:change="item.CSName = getStaffName(item.CSID)"
                      ></v-select>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-if="!(orderRole & 1024)">
                  {{ item.CSName }}
                </td> -->
                <td>
                  {{ item.CreatedDate | display_date('DD-MM-YYYY')}}
                  <br />
                  {{ item.CreatedDate | display_date('HH:mm:ss')}}
                </td>
                <td>
                  {{ item.BoughtDate | display_date('DD-MM-YYYY')}}
                  <br />
                  {{ item.BoughtDate | display_date('HH:mm:ss')}}
                </td>
                <td>
                  {{ item.CompletedDate | display_date('DD-MM-YYYY')}}
                  <br />
                  {{ item.CompletedDate | display_date('HH:mm:ss')}}
                </td>
                <!-- <td>
                  {{ item.SignalDatePackage | display_date('DD-MM-YYYY')}}
                  <br />
                  {{ item.SignalDatePackage | display_date('HH:mm:ss')}}
                </td> -->
                <!-- <td class="text-center">
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-icon medium @click="gotoDetail(item.ID)" v-on="on">fa-external-link-alt</v-icon>
                      </template>
                      <span>Chi tiết đơn hàng</span>
                  </v-tooltip>
                </td> -->
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogDeposit" max-width="300px">
      <v-card>
        <v-card-title>
          <span class="title">Cập nhật đơn hàng</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md pa-0>
            <v-layout row wrap>
              <v-flex lg12>
                <v-select v-model="selectedActionType" :items="bulkActionType" item-value="value" item-text="name"></v-select>
              </v-flex>
              <v-flex lg12>
                <h-currency-input v-if="selectedActionType == 1" label="Tổng tiền cọc (₫)" v-model="actionValueNumber" :decimal="0"></h-currency-input>
                <h-currency-input v-if="selectedActionType == 2" label="Tỷ giá" v-model="actionValueNumber" :decimal="0"></h-currency-input>
                <h-currency-input v-if="selectedActionType == 3" label="Lãi tỷ giá" v-model="actionValueNumber" :decimal="0"></h-currency-input>
                <h-currency-input v-if="selectedActionType == 4" label="Tỷ lệ cọc" v-model="actionValueNumber" :decimal="0"></h-currency-input>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="dialogDeposit = false">Đóng</v-btn>
          <v-btn color="red darken-1 white--text" @click="depositBlance">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogNew" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="title">Tạo mới đơn hàng</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md pa-0>
            <v-layout row wrap>
              <v-flex lg4 md4 sm12>
                <v-autocomplete label="Khách hàng" v-model="newOrder.UID" :items="customers" item-text="Username" item-value="ID"></v-autocomplete>
              </v-flex>
              <v-flex lg12 md12 sm12 xs12>
                <p class="body-2 font-weight-bold text-red">Danh sách sản phẩm</p>
                <v-list>
                  <v-list-item v-for="(item, index) in newOrder.Products" :key="item.ID">
                    <v-list-item-content>
                      <v-flex lg2 md2 sm12>
                    <v-text-field label="Link sản phẩm" v-model="item.Link"></v-text-field>
                    </v-flex>
                    <v-flex lg2 md2 sm12>
                      <!-- <v-text-field label="Link ảnh" v-model="item.Image"></v-text-field> -->
                      <img style="max-width: 56px; max-height: 56px" v-if="!hideImage" v-bind:src="`${getImages(index)}`" ref="imageFile"/>
                    </v-flex>
                    <v-flex lg4 md4 sm12>
                      <v-text-field label="Thuộc tính" v-model="item.Property"></v-text-field>
                    </v-flex>
                    <v-flex lg2 md2 sm12>
                      <h-currency-input label="Đơn giá (¥)" :decimal="1" v-model="item.Price"></h-currency-input>
                    </v-flex>
                    <v-flex lg2 md2 sm12>
                      <h-currency-input label="Số lượng" :decimal="0" v-model="item.Quantity"></h-currency-input>
                    </v-flex>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="newOrder.Products.splice(index, 1);">
                        <v-icon>fa-trash-alt</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-layout row wrap>
                  <v-flex lg2 md2 sm12>
                    <v-text-field label="Link sản phẩm" v-model="newProduct.Link"></v-text-field>
                  </v-flex>
                  <v-flex lg2 md2 sm12>
                    <v-text-field label="Link ảnh" v-model="newProduct.Image"></v-text-field>
                  </v-flex>
                  <v-flex lg2 md2 sm12>
                    <div class="col-md-12 box-right fb flex-c text-red form-group" style="padding: 0;">
                        <div class="row">
                            <div class="col-md-4">
                                <img style="max-width: 56px; max-height: 56px" v-if="!hideImage" v-bind:src="newProduct.Image" ref="imageFile"/>
                            </div>
                            <div class="col-md-8">
                                <input type="file" ref="fileInput" style="display: none" @change="previewImage" accept="image/*" > 
                                <button @click="chooseImage(newProduct)" style="cursor: point">Tải ảnh</button>
                            </div>
                        </div>
                    </div>
                  </v-flex>
                  <v-flex lg3 md3 sm12>
                    <v-text-field label="Thuộc tính" v-model="newProduct.Property"></v-text-field>
                  </v-flex>
                  <v-flex lg1 md1 sm12>
                    <h-currency-input label="Đơn giá (¥)" :decimal="1" v-model="newProduct.Price"></h-currency-input>
                  </v-flex>
                  <v-flex lg1 md1 sm12>
                    <h-currency-input label="Số lượng" :decimal="0" v-model="newProduct.Quantity"></h-currency-input>
                  </v-flex>
                  <v-flex lg1 md1 sm12>
                    <v-btn icon large @click="appendProduct"><v-icon color="#dc3545">far fa-plus-square</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="dialogNew = false">Đóng</v-btn>
          <v-btn color="red darken-1 white--text" @click="createNewOrder">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VWidget from "@/components/VWidget";
import { mapState } from "vuex";
import configs from "../../configs";
import _ from "lodash";
import Filtering from "@/components/Filtering";
import { sendErrorNotice, sendSuccessNotice, makeid } from "@/commons/utils";
import firebase from 'firebase';
export default {
  components: {
    VWidget,
    "hpo-filter": Filtering
  },
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      imageData: null,
      hideImage: false,
      search: "",
      selectedOrders: [],
      selectedActionType: 1,
      bulkActionType: [
        {value: 1, name: 'Tổng cọc'},
        {value: 2, name: 'Tỷ giá'},
        {value: 3, name: 'Lãi tỷ giá'},
        {value: 4, name: 'Tỷ lệ cọc'}
      ],
      headers: [       
        {
          text: "Mã đơn hàng",
          value: "ID",
          filterable: true,
          quickSearch: true,
          dataType: dataType["Number"]
        },
        {
          text: "Trạng thái",
          value: "Status",
          filterable: true,
          dataType: dataType["Enum"],
          values: configs.ORDER_STATUS
        },
        {
          text: "Ảnh SP",
          value: "image_origin",
          sortable: false,
          filterable: false,
          dataType: dataType["String"]
        },
        {
          text: "Dingdanhao",
          value: "OrderCodeCN",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: "Mã bill",
          value: "TransactionCode",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: "Thực mua",
          value: "RealAmount",
          align: "end",
          filterable: true,
          decimal: 2,
          dataType: dataType["Number"]
        },
        {
          text: "Bình luận",
          value: "ReviewBonus",
          align: "end",
          filterable: true,
          decimal: 2,
          dataType: dataType["Number"]
        },
        {
          text: "Tổng tiền",
          value: "TotalAmountVND",
          align: "end",
          filterable: true,
          dataType: dataType["Number"]
        },
        // {
        //   text: "Tiền cần cọc",
        //   value: "MinDeposit",
        //   align: "end",
        //   filterable: true,
        //   dataType: dataType["Number"]
        // },
        {
          text: "Khách hàng",
          value: "CustomerName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Nhân viên lên đơn",
          value: "BuyerName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        // {
        //   text: "Nhân viên hỗ trợ",
        //   value: "CSName",
        //   quickSearch: true,
        //   filterable: true,
        //   dataType: dataType["String"]
        // },
        {
          text: "Ngày lên đơn",
          value: "CreatedDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Ngày mua hàng",
          value: "BoughtDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        {
          text: "Ngày hoàn thành",
          value: "CompletedDate",
          filterable: true,
          dataType: dataType["Date"]
        },
        // {
        //   text: "Ngày ký nhận",
        //   value: "SignalDatePackage",
        //   filterable: true,
        //   sortable: true,
        //   dataType: dataType["Date"]
        // },
        // {
        //   text: "Thao tác",
        //   align: "center",
        // }
      ],
      pagination: {
          ...configs.PAGINATION,
          sortBy: ["ID"]
      },
      selectedTab: 0,
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      listStatus: configs.ORDER_STATUS,
      statusEnum: configs.ORDER_STATUS_ENUM,
      viewName: "order_list",
      filterConditions: [],
      quickFilterConditions: [],
      dialogDeposit: false,
      actionValueNumber: 0,
      dialogNew: false,
      newProduct: {
        Link: '',
        Image: '',
        Property: '',
        Price: 0,
        Quantity: 1
      },
      newOrder: {
        UID: 0,
        Products: []
      },
      newOrderCodeCN: '',
      newTransactionCode: ''
    };
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
        this.filter_data();
      },
      deep: true
    },
    orders: function(val) {
      this.selectedOrders = [];
    }
  },
  computed: {
    ...mapState({
      orders: state => state.main_order.all.data,
      orderSummary: state => state.main_order.all.summary,
      total_rows: state => state.main_order.all.total,
      loading: state => state.main_order.loading,
      staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return ((o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) || ((o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService);
        }),
      p_staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
        }),
      cs_staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return (o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        }),
      customers: state => state.customer.master.data
    }),
    pages() {
      return Math.ceil(
        this.total_rows / this.options.itemsPerPage
      );
    },
    paymentRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
    },
    adminRole() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    orderRole() {
      return this.$store.state.authentication.role.Orders;
    },
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.$store.dispatch("main_order/getListOrders", param);
      this.getCustomerList();
    },
    gotoDetail: function(id) {
      let routeData = this.$router.resolve({
        name: "OrderDetail",
        params: {
          Pid: id,
          title: `Chi tiết đơn hàng (#${id})`
        }
      });
      window.open(routeData.href, "_blank");
    },
    getStaffName(staffId) {
      const staff = _.find(this.staffs, {
        ID: staffId
      });
      return staff ? staff.FullName : "";
    },
    saveStaff(orderId, buyerId, csId) {
      var order_ids = _.map(this.selectedOrders, (item) => { return item.ID });
      if(order_ids.length == 0) {
        order_ids = [orderId]
      }
      this.$store.dispatch("main_order/updateMultiple", {
          OrderIDs: order_ids,
          CustomerServiceID: csId,
          BuyerID: buyerId
      });
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions, isFilter) {
      this.quickFilterConditions = filterConditions;
      if(isFilter) {
        this.filter_data();
      }
    },
    get_order_status_color(value){
      const objStatus = configs.ORDER_STATUS.find(obj => obj.value === value);
      return objStatus ? objStatus.color : 'black';
    },
    depositDialog() {
      if(!this.adminRole && !this.paymentRole) {
        sendErrorNotice("Bạn không có quyền thực hiện chức năng này!");
        return;
      }
      if(this.selectedOrders.length == 0) {
        sendErrorNotice("Hãy chọn các đơn muốn phân bổ!");
        return;
      }
      this.dialogDeposit = true;
    },
    depositBlance() {
      if(this.selectedActionType == 1 && this.actionValueNumber <= 0) {
        sendErrorNotice("Hãy nhập tổng tiền cọc muốn phân bổ!");
        return;
      }
      if(this.selectedActionType == 2 && this.actionValueNumber <= 0) {
        sendErrorNotice("Hãy nhập tỷ giá tệ!");
        return;
      }
      if(this.selectedActionType == 3 && this.actionValueNumber <= 0) {
        sendErrorNotice("Hãy nhập lãi tỷ giá tệ!");
        return;
      }
      if(this.selectedActionType == 4 && this.actionValueNumber <= 0) {
        sendErrorNotice("Hãy nhập tỷ lệ cọc!");
        return;
      }

      if(this.selectedActionType == 1) {
        var needDepositOrder = _.filter(this.selectedOrders, (item) => { return item.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc; });
        var ids = _.map(needDepositOrder, 'ID');
        var currentNeedDeposit = needDepositOrder.map(item => item.MinDeposit).reduce((prev, curr) => prev + curr, 0);
        var blance = this.actionValueNumber - currentNeedDeposit;
        var param = {
          pagination: this.pagination,
          filter: this.filterConditions,
          quickFilter: this.quickFilterConditions
        };
        this.$store.dispatch("main_order/depositBlance", {'ids': ids, 'totalDeposit': blance, 'param': param});  
      }
      else if(this.selectedActionType == 2) {
        var needUpdateOrder = _.filter(this.selectedOrders, (item) => { return item.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc || item.Status == configs.ORDER_STATUS_ENUM.cho_bao_gia; });
        var ids = _.map(needUpdateOrder, 'ID');
        this.$store.dispatch("main_order/updateMultiple", {
          OrderIDs: ids,
          ExchangeRate: this.actionValueNumber,
          ReCal: true
        });
      }
      else if(this.selectedActionType == 3) {
        var needUpdateOrder = _.filter(this.selectedOrders, (item) => { return item.Status != configs.ORDER_STATUS_ENUM.hoan_thanh; });
        var ids = _.map(needUpdateOrder, 'ID');
        this.$store.dispatch("main_order/updateMultiple", {
          OrderIDs: ids,
          ExchangeRevenue: this.actionValueNumber
        });
      }
      else if(this.selectedActionType == 4) {
        var needUpdateOrder = _.filter(this.selectedOrders, (item) => { return item.Status == configs.ORDER_STATUS_ENUM.cho_dat_coc || item.Status == configs.ORDER_STATUS_ENUM.cho_bao_gia; });
        var ids = _.map(needUpdateOrder, 'ID');
        this.$store.dispatch("main_order/updateMultiple", {
          OrderIDs: ids,
          MinDepositRate: this.actionValueNumber
        });
      }
      
      this.dialogDeposit = false;
    },
    getCustomerList() {
      this.$store.dispatch("customer/getCustomerList", {
          is_master: 1,
          fields: ["ID", "Username", "BuyerID", "CustomerServiceID", "ReceivePlaceID"]
      });
    },
    appendProduct() {
      this.newOrder.Products.push(this.newProduct);
      this.newProduct = {
        Link: '',
        Image: '',
        Property: '',
        Price: 0,
        Quantity: 1
      }
    },
    createData() {
      this.dialogNew = true;
    },
    createNewOrder() {
      if(this.newOrder.Products.length == 0) {
        sendErrorNotice("Hãy nhập danh sách sản phẩm!");
        return;
      }
      if(this.newOrder.UID == 0) {
        sendErrorNotice("Hãy chọn khách hàng!");
        return;
      }
      const cust = _.find(this.customers, {'ID': this.newOrder.UID});
      var param = {
        pagination: this.pagination,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      this.newOrder.Username = cust.Username;
      this.newOrder.BuyerID = cust.BuyerID;
      this.newOrder.CustomerServiceID = cust.CustomerServiceID;
      this.newOrder.ReceivePlaceID = cust.ReceivePlaceID;

      this.$store.dispatch("main_order/createNew", {'data': this.newOrder, 'param': param});
      this.newOrder = {
        UID: 0,
        Products: []
      };
      this.dialogNew = false;
      sendSuccessNotice('Tạo mới đơn hàng thành công.');
    },
    chooseImage(item) {
        this.newProduct.Image = '';
        this.$refs.fileInput.click();
    },
    previewImage(event) {
        this.imageData = event.target.files[0];
        this.$refs.fileInput.value = null;
        this.onUpload()
    },
    onUpload() {
        const arrFileType = this.imageData.name.split('.');
        const storageRef=firebase.storage().ref(`images/${makeid(30)}.${arrFileType[arrFileType.length - 1]}`).put(this.imageData);
        storageRef.on(`state_changed`, snapshot => {
                
            },
            error => {
              sendErrorNotice('File ảnh chỉ được tối đa 1Mb!');
            },
            () => {
                storageRef.snapshot.ref.getDownloadURL().then((url) => {
                    this.newProduct.Image =url;
                    this.hideImage = true;
                    this.$nextTick(() => this.hideImage = false);
                });
            }
        );
    },
    getImages(index) {
        return this.newOrder.Products[index].Image;
    },
    saveDingdanhao(orderObj) {
      this.$store.dispatch("ordercn/setDetail",
        {
          data: {OrderID: orderObj.ID, OrderCodeCN: this.newOrderCodeCN ? this.newOrderCodeCN : orderObj.OrderCodeCN, RealAmount: orderObj.RealAmount},
          id: orderObj.OrderCodeID
        }
      );
      this.newOrderCodeCN = '';
    },
    saveOrder(orderObj) {
      this.$store.dispatch("main_order/updateOrderDetail", {OrderID: orderObj.ID, ReviewBonus: orderObj.ReviewBonus});
    },
    saveTransactionCode(orderObj) {
      this.$store.dispatch(
        "orderTransaction/setDetail",
        {
          data: {
            OrderID: orderObj.ID,
            OrderCodeID: orderObj.OrderCodeID,
            Username: orderObj.CustomerName,
            UID: orderObj.UID,
            PackageType: 0,
            TransactionView: 'order_detail',
            TransactionCode: this.newTransactionCode,
            Status: configs.PACKAGE_STATUS_ENUM.DANG_DI_CHUYEN,
            CreatedBy: this.$store.state.authentication.user.username
          },
          id: 0,
          param: this.trancode_param
        }
      );
      this.newTransactionCode = '';
    }
  },
  created() {
    this.$store.dispatch("commons/getAllUsers");
  },
  mounted() {
    this.filterConditions = [];
    this.quickFilterConditions = [];
    this.$root.$emit('REQUEST_ROOT_OPTION', {});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_SAVE_DATA", this.depositDialog);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_SAVE_DATA", this.depositDialog);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
  }
};
</script>
<style lang="css" scoped>
.image-product {
  width: 64px;
  height: 64px;
}
</style>